import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Col, Row } from "react-bootstrap"
import Layout from "../components/layout"
import PieceListItem from "../components/pieceListItem"
import SEO from "../components/seo"
import SiteBackground from "../components/siteBackground"


const Installations = () => {
  const data = useStaticQuery(graphql`
    query {
      info: allInstallationJson(sort: {fields: sortDate, order: DESC}) {
        nodes {
            date
            title
            size
            material
            desc
            short_desc
            directory
            sortDate
            image: mainImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
  `)
  const pieceType = "installation"
  return (
    <SiteBackground>
      <Layout>
        <SEO title="Elnaz Nourizadeh Installations" />
        <h1>Installations</h1>
        <Row>
          {data.info.nodes.map(i =>
            <Col md={6} lg={4} className="d-flex">
              <PieceListItem item={i} pieceType={pieceType} />
            </Col>
          )}
        </Row>
      </Layout>
    </SiteBackground>
  )
}

export default Installations
